.warehouse-card{
    position: relative;
    overflow: hidden;
}
.warehouse-card-body{
    min-height: 70px;

}
.cardInfoTag {
    position: absolute;
    right: -50px;
    width:50px;
    padding:2px ;
    color:aliceblue;
    top:0px;
    background-color: rgba(32, 32, 32, 0.514);
    transition: 1s all;
    
  }

.warehouse-card:hover .cardInfoTag{
    right:0px;
    top:0px; 
}