.hero {
    position: relative;
    width: 100%;
    height: 72vh;
    display: flex;
    overflow: hidden;
    border:none;
    justify-content: center;
    
 }
 .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(59 123 194);
 }
 
 .hero-content {
    position: absolute;
    font-family: "Monserrat", sans-serif;
    color: white;
    text-align: center;
    margin: 0.425rem;
 }
 .top-title{
    margin-top: 6.5rem !important;
 }
 .hero-title {
    font-size: 1.9rem;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 4rem !important;
 }
 .hero-subtitle {
    font-size: 1.4rem;
    font-weight: 200;
    margin-top: 1rem;
 }
 .hero-button {
    background-color: var(--mainColor2);
    color: white;
    border: 1px solid var(--mainColor);
    margin-top: 5rem;
    font-family: "Monserrat", sans-serif;
    cursor: pointer;
    border-radius: 20px;
    padding :6px 30px;
    transition: 1s all;
 }
 .hero-button:hover {
    background-color: var(--mainColor);
    border: 1px solid var(--mainColor2);
 }
 
 
@media (min-width: 1300px) {
    .top-title{
        margin-top: 7rem;
     }
    .hero-title {
        font-size: 2rem;
        margin-top: 11rem;
     }
     .hero-subtitle {
        font-size: 1.4rem;
       
     }
  }

  /* wave */
  .custom-shape-divider-bottom-1701750236 {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   overflow: hidden;
   line-height: 0;
   transform: rotate(180deg);
}

.custom-shape-divider-bottom-1701750236 svg {
   position: relative;
   display: block;
   width: calc(100% + 1.3px);
   height: 74px;
   transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1701750236 .shape-fill {
   fill: #FFFFFF;
}

/** For mobile devices **/
@media (max-width: 767px) {
   .custom-shape-divider-bottom-1701750236 svg {
       width: calc(100% + 1.3px);
       height: 64px;
   }
   .hero{
      height:100vh
   }
}
