.service-img{
    width:220px;
    height:250px;
}
.h-fixed{
    height: 500px;
    overflow: hidden;
}
.service-card{
    position: relative;
}
.service-information-pannel{
    width: 100%;
    position: absolute;
    height:505px;
    top: 500px;
    background-color: #145587ec;
    transition: 1s all ease-in-out;
}
.service-card:hover .service-information-pannel{
    top: 0px;
}
