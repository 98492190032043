.contact-circle{
    width:65px;
    height: 45px;
    border: 1px solid white;
    border-radius: 50%;
}
@media (min-width: 1300px) {
    .contact-circle{
        width:57px;
        height: 49px;
    }
  }