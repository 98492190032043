.dashboard_card{
    height: 150px;
}
.dash-icon-card{
    flex:1;
    font-size: 35px;
    margin-top: 22px;
    padding-right: 8px; 

 }
 .dash-text-card{
    flex:5;
 }
@media (min-width: 1400px) {
    .dash-icon-card{
        flex:2;
        font-size: 45px; 
     }
}

@media (max-width: 500px) {
    .dashboard_card{
        height: 100px;
    }
}



