.s-right{
    margin: 10% 0% ;
    
}
h1{
    text-transform: uppercase;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.3em;
}
.color-s1{
    box-sizing: border-box;
    background-color: var(--mainColor);
    color: white;
    padding: 0px 5px;
}
.color-s2{
    color:var(--mainColor)
}
.separator-div{
    background-color: var(--mainColor);
    height: 5px;
    width:100%;
    margin: 10px 0px;
}
.para{
    margin-top: 40px;
    font-size: 17px;
}
.shedule-btn{
    margin-top: 20px;
    background-color:var(--mainColor2);
    color: white;
    text-transform: uppercase;
    border-radius: 30px;
    border:none;
    font-weight: bold;
    padding: 13px 50px;

}

.sheduler-wrapper{
    margin-top: 130px;
}
@media (min-width: 1500px) {
    
  }