.loader-div{
    position: fixed;
    top:0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color:rgba(128, 128, 128, 0.231);
    display: flex;
    justify-content: center;
    align-items: center;
}
.loadder{
    color: rgb(86, 86, 86);
}