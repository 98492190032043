nav {
    box-shadow: 0 1px 5px 0 rgba(122, 121, 121, 0.2);
}

.right {
    display: flex;
    justify-content: right;
}

.center {
    display: flex;
    justify-content: center;
}

.text-justified{
    text-align: justify;
}

.custome-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mt-nav {
    margin-top: 85px;
}


.v-center {
    display: flex;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.mainColor {
    color: var(--mainColor)
}

.mainColor2 {
    color: var(--mainColor2)
}

.uppercase {
    text-transform: uppercase;
}

.title-section {
    margin: 120px 0 70px 0;
}

.title-section2 {
    margin: 100px 0 100px 0;
}

.card-shadow {
    box-shadow: 0 4px 8px 0 rgba(106, 105, 105, 0.2), 0 6px 20px 0 rgba(177, 176, 176, 0.19);
    border: none;
}

.link {
    text-decoration: none;
    color: var(--mainColor2)
}

.fa-2x {
    font-size: 22px;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    z-index: 90 !important;
}
.css-n7rtur-MuiPaper-root-MuiAppBar-root{
    z-index: 95 !important;
}

.css-o5srbh-MuiPaper-root-MuiAppBar-root {
    background-color: #1976d2 !important;
    color: rgba(0, 0, 0, 0.87);
}

.fa-xmark {
    cursor: pointer;
    font-size: 23px;
}

.fa-xmark:hover {
    color: tomato;
}

.back-div {
    width: 100%;
    height: 750px;
    position: absolute;
    top: 0;
    background-color: var(--heroBgColor);
}

.back-small-div {
    width: 100%;
    height: 270px;
    position: absolute;
    padding: 120px 0 0 0;
    top: 0;
    background-color: var(--heroBgColor);
}

.back-small-div-container{
    position: relative;
    margin-top: 110px;
    
}

.back-div-height-1000{
    height: 1000px !important;
}
.back-div-height-800{
    height: 750px !important;
}

.back-div-card {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

@media (min-width: 1300px) {
    .back-div {
        height: 100vh;
    }

    .back-div-card {
        margin-top: 50px;
    }
}

.mt-10{
    margin-top: 100px;
}
.mt-15{
    margin-top: 150px;
}
.mt-20{
    margin-top: 200px;
}
.mt-23{
    margin-top: 230px;
}
.mt-25{
    margin-top: 250px;
}
.spaceBetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.center{
    display:"flex";
    justify-content:"center"
}
.alignCenter{
    display: flex;
    align-items: center;
}

/*mobile friendly css*/

@media (max-width: 500px) {
    h2 {
      font-size: 17px !important; /* Adjusted font size for mobile screens */
      color:red
    }
    small{
        font-size: 13px !important;
    }
    .search-panel{
        height: 40px; /*search pannel component*/
    }
   
  }