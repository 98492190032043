.footer-section{
    margin-top: 200px;
    background-color: rgb(240 239 239 / 59%);
    padding-top: 50px;
    box-shadow: 0 4px 8px 0 rgb(221 217 217), 0 25px 50px 0 rgba(177, 176, 176, 0.19);
    border:none;
}
.footer-logo{
    width: 230px;
    height: 140px;
}
.footer-divider{
    width:340px;
    height: 3px;
    background-color: var(--mainColor);
    margin: 10px 0 0 0;
}
.sec1-info{
    margin: 10px 0 0 0;
}
.copyright-section{
    width:100%;
    display: flex;
    margin: 10px 0 0 0;
    padding: 5px;
    justify-content: center;
    align-items: center;
}
.divider{
    width: 100%;
    margin: 40px 0px 10px 0;
    height: 1px;
    background-color: rgba(128, 128, 128, 0.285);
}