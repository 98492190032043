.logo{
    width:100px;
    height:50px;
}
.nav-item{
    padding:3px 5px;
    font-weight: 500;
}
.nav-link{
    cursor:pointer;
}

@media (min-width: 1500px) {
    .nav-item{
        font-size:20px;
    }
    .logo{
        width:122px;
        height:62px;
    }
  }


.siteTabs{
  position: fixed;
  right: 0;
  top:45%;
  z-index: 9999;
}
.site-tab{
  position: relative;
  right:-175px;
  width:220px;
  margin-top: 5px;
  height:50px;
  border-radius: 10px 0 0 10px;
  background-color:var(--mainColor2);
  transition: 2s all;
  display: flex;
  align-items: center;
}
.site-tab:hover{
  right:0px;
}
.number{
  color:white;
  margin-top: 13px;
  font-size: 20px;
}
